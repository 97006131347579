.text__chat {
  height: 85vh;
  background: white;
  box-shadow: 0px 4px 22px #00000029;
  position: absolute;
  top: 6vh;
  width: 450px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  right: 25vw;
}

.text__contain {
  width: 90%;
  margin: auto;
}

.person__chat {
  top: 40vh;
  left: 15vw;
  width: 25vw;
}

.header__chat {
  width: 90%;
  margin: auto;
  justify-content: space-around;
  display: flex;
  height: 10%;
}

.body__chat {
  margin: auto;
  width: 90%;
  height: 80%;
}

.bottom__chat {
  position: absolute;
  width: 90%;
  height: 10%;
}

.chat__input {
  width: 80%;
  margin: auto;
}

.chat__look {
  background: #f9fafa;
  height: 30px;
  border-radius: 10px;
  width: 100%;
  border: 0px;
  font-size: 15px;
  padding-left: 35px;
  color: #7d8db5;
  box-sizing: border-box;
}

.chat__look:focus {
  outline: none;
}

.search__icon {
  color: #b0b6c3;
  left: 25px;
  margin: auto;
  text-align: center;
  position: absolute;
  padding: 8px;
}

.icon__container {
  background: #eff5f5;
  height: 30px;
  width: 8%;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: auto;
  margin: auto;
}

.my__icon {
  color: #676767;
}

.text__contain {
  height: 10%;
}

.chat__body {
  height: 95%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
}

.chat__body::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.chat__item__contain {
  display: flex;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
}

.chat__content {
  width: 100%;
  height: 100%;
  background: blue;
}

.chat__item {
  font-family: "Montserrat-regular";
  padding: 20px;
  margin: 0;
  height: fit-content;
  position: relative;
  border-radius: 10px;
  font-size: 12px;
}

.me {
  width: calc(50% - 60px);
  margin: auto;
  margin-left: 2%;
  background: #eff5f5;
  color: #25396f;
  border-bottom-left-radius: 0px;
}

.other {
  border-bottom-right-radius: 0px;
  width: 50%;
  background: #003264;
  margin-left: auto;
  margin-right: 2%;
  color: white;
  animation: 1s fadeIn;
}

.chat__item.other:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #003264;
  border-left: none;
  bottom: 0;
  right: -3%;
}

.chat__item.me:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #eff5f5;
  border-right: none;
  bottom: 0;
  left: -4%;
}

.chat__icon {
  margin: auto;
  height: 65px;
  margin-left: 0;
  margin-right: 0;
}

.classic__bottom {
  height: 100%;
  justify-content: space-around;
  display: flex;
}

.bottom__contain {
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.pictures__contain {
  height: 40%;
  width: 20%;
  border-right: 1px solid #b0b6c3;
}

.bottom__input {
  font-family: "Montserrat-regular";
  width: 80%;
  border-style: none;
  color: #b0b6c3;
}

.bottom__input:focus {
  outline: none;
}

.input__contain {
  width: 60%;
}

.test__button {
  margin: auto;
  padding: 15px;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: none;
}

.test__icon {
  margin: auto;
  float: right;
  display: inline;
}

.answer__contain {
  height: 90%;
  background: #eff5f5;
  border-radius: 15px;
}

.answer__emoji {
  height: 90%;
}

.answer__bottom {
  font-family: "Montserrat-regular";
  padding: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;
  font-size: 12px;
  height: 70%;
  display: flex;
  border-radius: 10px;
  box-shadow: none;
  margin: auto;
  cursor: pointer;
}

.answer__bottom:active {
  background-color: #FFDC0E;
  color: white
}

.button__negative {
  background: white;
  color: #878484;
}

@keyframes fadeIn {
  99% {
    display: none;
  }
  100% {
    display: flex;
  }
}

.upeu__logo__test {
  width: 100px;
  height: 100px;
  z-index: 1;
}
