.my__button {
  width:100%;
  border: none;
  text-shadow: 0px 3px 4px #00000029;
  font-size: 20px;
  font-weight: bold;
  padding-inline: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 4px 12px #00000029;
  font-family: "Montserrat-semiBold";
}

.my__button:hover {
  filter: brightness(0.9);
}

.primary {
  background: #FFDC0E;
  color: white;
}

.secundary {
  background: white;
  color: black;
}
