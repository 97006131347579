.body__header {
  display: flex;
  height: 4%;
  padding-bottom: 5px;
}

.chat__user {
  font-family: "Montserrat-bold";
  font-size: 20px;
  font-weight: bold;
  color: #25396f;
  margin: 0;
}

.text__online {
  font-family: "Montserrat-regular";
  margin: 0;
  color: #b0b6c3;
}

.chat__online {
  font-size: 15px;
  align-self: flex-end;
}

.online__point {
  font-weight: bold;
  margin: 0;
  align-self: flex-end;
  margin-bottom: -5px;
  color: #6fcf97;
  font-size: 20px;
}
