.partial-circle {
  position: absolute;
  height: 450px;
  width: 100%;
  overflow: hidden;
  top: 0;
}
.partial-circle:after {
  content: '';
  position: absolute;
  height: 145vh;
  width: 100vw;
  border-radius: 45%;
  bottom: 0;
  background: #003264;
}