.text__container {
  position: absolute;
  color: #fff;
}

.text__test {
  font-size: 25vh;
  font-weight: bold;
  bottom: 25vh;
  left: 33vw;
  transform: rotate(-9deg);
  font-family: "Montserrat-bold";
}
.text__upeu {
  font-size: 12vh;
  font-weight: bold;
  bottom: 32vh;
  right: 31vw;
  transform: rotate(-10deg);
  font-family: "Montserrat-semiBold";
  font-weight: 600;
}

.btn__row button{
  font-family: "Montserrat-bold";
}
