.svg__container {
  overflow: hidden;
  position: absolute;
  bottom: 0px;
}

.svg__container img {
  width: 88%;
}

.boy__container {
  height: 50vh;
  width: 25vw;
  left: 22vw;
}

.girl__container {
  height: 45vh;
  width: 25vw;
  right: 22vw;
}
