.view__background{
    background: url("../Assets/background.png");
    background-size: 100% auto;
    background-attachment: fixed;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0;
    left: 0px;
    opacity: 0.5;
    z-index: -3;
}