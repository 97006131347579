.form__select {
  border-radius: 50px;
  text-indent: 20px;
  width: 100%;
  margin-bottom: 10px;
  padding: 7px;
  border: 1px solid black;
  font-family: "Montserrat-semiBold";
}

.form__select:focus {
  outline: none;
}
