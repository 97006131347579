.result__logo {
  position: absolute;
  top: 7vh;
  width: 100%;
}

.result__container {
  position: absolute;
  top: 120px;
  height: 40vh;
  width: 100%;
}

.result__profile {
  margin: auto;
  width: 50vw;
  height: 100%;
  text-align: center;
}

.result__title {
  font-family: "Montserrat-bold";
  font-size: 45px;
  font-weight: bold;
  color: white;
}

.result__subtitle {
  font-family: "Montserrat-light";
  font-size: 25px;
  color: white;
}

.result__detail {
  margin-top: 20px;
  display: flex;
}

.profile__image__contain {
  width: 45%;
  padding-top: 15px;
}

.profile__image__box {
  display: flex;
  position: relative;
  height: fit-content;
}

.profile__image {
  margin: auto;
  float: right;
  margin-right: 20px;
  width: 80%;
  border-radius: 100%;
}

.profile__check {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 20%;
}

.profile__description {
  width: 70%;
}

.profile__title {
  font-family: "Montserrat-regular";
  text-align: start;
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.profile__text {
  font-family: "Montserrat-light";
  text-align: start;
  font-size: 19px;
  color: #ffffff;
  font-weight: 100;
}

.result__careers {
  position: absolute;
  top: 450px;
  text-align: center;
  height: fit-content;
  width: 100%;
}

.careers__title {
  font-family: "Montserrat-regular";
  color: #434242;
  font-size: 30px;
  margin-bottom: 20px;
}

.careers__list {
  text-align: center;
  justify-content: space-around;
  margin: auto;
  width: 80%;
}

.career__result {
  padding: 0 25px;
  display: inline-block;
  width: fit-content;
  margin-bottom: 25px;
}

.career__name {
  font-family: "Montserrat-bold";
  color: #2b2a2a;
  font-size: 25px;
  font-weight: bold;
}

.career__title {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.career_check {
  width: 50px;
}

.career__button {
  font-size: 16px;
}

.result__link {
  margin-top: 10px;
}

.my__link {
  font-family: "Montserrat-light";
  text-decoration: none;
  color: #717171;
  font-size: 35px;
}

.result__icon {
  position: absolute;
  top: 5vh;
  right: 20vw;
  font-size: 20px;
  color: white;
}

.back__icon {
  position: absolute;
  top: 5vh;
  left: 20vw;
  font-size: 20px;
  color: white;
}

.modal__background {
  width: 100%;
  height: 100%;
  background: #013264;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(1, 50, 100, 0.8); /* Black w/ opacity */
  overflow: auto;
  top: 0;
  position: fixed;
  z-index: 1;
}

.my__modal {
  z-index: 3;
  width: 600px;
  height: 400px;
  background: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
  box-shadow: 0px 4px 12px #00000029;
  position: absolute;
}

.modal__contain {
  color: #363636;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 30px;
  justify-content: center;
}

.modal__close {
  font-family: "Montserrat-bold";
  position: absolute;
  right: -25px;
  top: -25px;
  background: #fff;
  box-shadow: 0px 4px 12px #00000029;
  width: 50px;
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.modal__gracias {
  font-family: "Montserrat-bold";
  font-size: 50px;
}

.modal__hr {
  width: 80%;
  color: #d3d3d3;
}

.modal__text {
  font-family: "Montserrat-light";
  font-size: 40px;
  width: 80%;
  text-align: center;
  margin: auto;
}

.profile__image__contain__min {
  display: none;
  padding: 0;
  width: 100%;
}

.profile__image__contain__min .profile__image__box {
  width: 100px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.profile__image__contain__min .profile__image {
  margin-left: 0;
  margin-right: 0;
  width: 17vh;
}
.profile__image__contain__min .profile__check {
  right: -20px;
  width: 50%;
}
