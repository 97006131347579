.title__upeu__container {
  color: white;
}
.title__upeu {
  font-size: 55px;
  font-weight: bold;
  display: inline;
  float: left;
  border-right: 1px solid rgba(255, 255, 255, 0.89);
  padding-right: 10px;
  margin-top: -6px;
}

.upeu__name {
  margin-left: 10px;
  float: left;
  display: inline;
}

.universidad {
  font-size: 25px;
}
.name {
  font-size: 21px;
}
