.upeu__logo {
  position: absolute;
  bottom: 50px;
  right: 100px;
  height: 55px;
}

.logo__test {
  width: 100%;
}
