.btn_container{
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    bottom: 27%;
}

.btn__row{
    width: fit-content;
}