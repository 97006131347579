.no_responsive .foto {
  background-size: 1920px auto;
  width: 1920px;
  height: 1080px;
}
.no_responsive .view__background {
  background-size: 1920px auto;
  width: 1920px;
  height: 1080px;
}

.no_responsive .partial-circle {
  width: 1920px;
  height: 550px;
}

.no_responsive .partial-circle:after {
  height: 1166px;
  width: 1920px;
}

.no_responsive .results__logo__contain {
  width: 1920px;
}
.no_responsive .results__logo {
  height: 65px;
}
.no_responsive .result__icon {
  left: 1536px;
}
.no_responsive .result__container {
  top: 150px;
  width: 1920px;
}

.no_responsive .result__profile {
  width: 720px;
}
.no_responsive .profile__text,
.no_responsive .profile__title,
.no_responsive .result__subtitle {
  font-size: 25.5px;
}

.no_responsive .result__careers {
  top: 550px;
  width: 1920px;
}

.no_responsive .careers__title {
  font-size: 38px;
  margin-bottom: 50px;
}
.no_responsive .career__result {
  margin-bottom: 60px;
}

.no_responsive .career__button {
  display: none;
  font-size: 20px;
  padding-inline: 55px;
}

.no_responsive .my__link {
  font-size: 45px;
}

.no_responsive .result__icon {
  display: none;
}

.no_responsive .results__button {
  display: none;
}

.no_responisve .mas__informacion {
  display: none;
}

.no_responsive .career__name {
  font-size: 40px;
}
.no_responsive .career__name.another {
  font-size: 47px;
}

.no_responisve .career__name.choosed {
  font-size: 50px;
}
