.test__logo {
  position: absolute;
  width: 300px;
  top: 6vh;
  bottom: 0px;
  left: 18vw;
  height:fit-content
}
.test__text {
  position: absolute;
  width: 300px;
  top: 25vh;
  bottom: 0px;
  left: 18vw;
  font-size: 35px;
  color: white;
  font-family: "Montserrat-regular";
  height:fit-content;
}
