.r__icon__container {
  position: relative;
  margin: 25px;
}
.icon__row {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.registered__icon {
  display: block;
  width: 100%;
  
}

.check__icon {
  position: absolute;
  width: 35%;
  bottom: 0;
  right: 0;
}

.registered__title {
  font-family: "Montserrat-bold";
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin: 15px;
  color: #363636;
}

.registered__text {
  font-size: 20px;
  color: #363636;
  text-align: center;
  font-family: "Montserrat-light";
}
