.results__box {
  height: fit-content;
  background: white;
  box-shadow: 0px 4px 22px #00000029;
  position: absolute;
  top: 25vh;
  width: 450px;
  left: 30vw;
  padding-top: 35px;
  color: #363636;
  text-align: center;
}

.results__woman {
  width: 32vw;
  top: 10vh;
  right: 17vw;
}

.results__woman img {
  width: 100%;
}

.results__title {
  font-family: "Montserrat-bold";
  margin: 15px;
  font-size: 45px;
  font-weight: bold;
}

.results__hr {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 70%;
}

.results__name {
  font-family: "Montserrat-regular";
  margin: 0;
  font-size: 40px;
  font-weight: bold;
}

.results__text {
  font-family: "Montserrat-regular";
  margin: 0;
  font-size: 25px;
}

.results__visualize {
  font-family: "Montserrat-semiBold";
  margin: 0;
  font-size: 30px;
  font-weight: bold;
}

.results__buttons {
  margin-top: 25px;
  margin-bottom: 60px;
}

.results__button {
  margin: 5px;
  width: fit-content;
  font-size: 15px;
  border-radius: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.results__logo__contain{
  top:6vh;
  left:0;
  right:0;
  position: absolute;
  height: 65px;
  width: 100%;
  display: flex;
}

.results__logo{
  margin: auto;
  height:100%;
}