.form__input {
  border-radius: 50px;
  text-indent: 20px;
  width: calc(100% - 16px);
  margin-bottom: 10px;
  padding: 7px;
  border: 1px solid black;
  font-family: "Montserrat-light";
}

.form__input:focus {
  outline: none;
}
