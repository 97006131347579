.choose__content {
  margin: auto;
  width: 100%;
  align-items: center;
  position: absolute;
}

.choose__subtitle {
  font-family: "Montserrat-medium";
  margin: 0;
  color: white;
  display: block;
  text-align: center;
  font-size: 30px;
  width: 100%;
}

.choose__logo {
  height: 15vh;
  width: 100%;
  position: relative;
}

.choose__careers {
  text-align: center;
  justify-content: center;
  width: 80vw;
  height:60vh;
  overflow-y: auto;
  display: block;
  margin: auto;
}

.next__box {
  text-align: center;
  justify-content: center;
  display: flex;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  background: white;
  bottom: 0px;
  margin: 0 auto;
  width: 60vw;
  height: fit-content;
  box-shadow: 0px 4px 22px #00000029;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding-top:15px;
  padding-bottom:15px
}

.choosed__images {
  display: inline-block;
  margin-left: 50px;
}

.choosed__image {
  margin: 2px;
  width: 74px;
  height: 68px;
  border: 2px solid #FFDC0E;
  border-radius: 15px;
  display: inline-block;
}

.choose__buttom {
  width: 300px;
  display: inline-block;
  margin: auto;
  margin-left: 15px;
  margin-right: 50px;
}

.upeu__logo__choose {
  margin: auto;
  height: 10vh;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
