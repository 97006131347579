.choose__box {
  display: inline-block;
  position: relative;
}

.box__image {
  width: 80%;
  height: 48%;
}

.box__title {
  font-family: "Montserrat-bold";
  font-weight: bold;
}

.box__checked {
  display: flex;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 0;
  right: 0;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
}

.box__checked p {
  margin: auto;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 0px 3px 6px #00000029;
  color: #FFDC0E;
}

.box__text {
  font-family: "Montserrat-regular";
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  margin-bottom: 20px;
  height: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.foto{
  position: relative;
  background: white;
  height: 105vh;
  width: 100%;
  z-index: 0;
  padding-bottom: 40px;
}
