.my__box {
  background: white;
  border-radius: 35px;
  height: fit-content;
  position: absolute;
  box-shadow: 0px 4px 22px #00000029;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}
.box__notAbsolute {
  background: white;
  border-radius: 30px;
  height: 230px;
  box-shadow: 0px 4px 22px #00000029;
  margin: 10px;
  width: 150px;
  padding: 15px;
}
