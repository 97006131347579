.container {
  width: 70%;
  margin: auto;
  text-align: center;
}

.div__point {
  width: 15px;
  height: 15px;
  background: #013264 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50px;
  margin-inline: auto;
}

.div__point.point__1 {
  background: #FFDC0E 0% 0% no-repeat padding-box;
}

@font-face {
  font-family: "Montserrat-black";
  src: url(../Assets/fonts/Montserrat-Black.ttf) format("opentype");
}

@font-face {
  font-family: "Montserrat-bold";
  src: url(../Assets/fonts/Montserrat-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Montserrat-regular";
  src: url(../Assets/fonts/Montserrat-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Montserrat-semiBold";
  src: url(../Assets/fonts/Montserrat-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "Montserrat-light";
  src: url(../Assets/fonts/Montserrat-Light.ttf) format("opentype");
}

@font-face {
  font-family: "Montserrat-medium";
  src: url(../Assets/fonts/Montserrat-Medium.ttf) format("opentype");
}