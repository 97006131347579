.mycol-4 {
  width: 500px;
}

.mycol-3 {
  width: 350px;
}

@media only screen and (max-width: 1300px) {
  .next__box {
    width: 90vw;
  }
  .test__text {
    left: 10vw;
    font-size: 35px;
  }
  .test__logo {
    left: 10vw;
  }
}
@media only screen and (max-width: 1100px) {
  .partial-circle:after{
    border-radius: 30%;
  }

  .svg__container.person__chat {
    top: 20vh;
  }
  .result__container {
    top: 15vh;
  }

  .test__text {
    display: none;
  }

  .test__logo {
    display: none;
  }

  .person__chat {
    left: 5vw;
  }

  .result__profile {
    width: 65vw;
  }
  .results__logoo {
    visibility: hidden;
  }
}

@media only screen and (max-width: 800px) {
  .result__icon {
    top: 15px;
    right: 15px;
  }
  .back__icon {
    top: 15px;
    left: 15px;
  }
  /* For tablets: */
  .mycol-4 {
    width: 80vw;
  }

  .text__chat {
    width: 450px;
    margin: auto;
    right: 0;
    left: 0;
  }

  .results__box {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .result__title {
    font-size: 30px;
  }

  .result__subtitle {
    font-size: 25px;
  }

  .result__detail {
    display: block;
  }
  .profile__image__contain {
    margin: auto;
  }

  .profile__description {
    width: 100%;
    align-items: center;
    text-align: center;
    margin: auto;
  }

  .result__container {
    top: 5vh;
  }

  .result__logo {
    display: none;
  }
  .result__profile {
    width: 50vw;
  }

  .text__test {
    top: 0;
    font-size: 110px;
    bottom: 40vh;
    right: 0;
    left: 0;
    margin: auto;
    width: fit-content;
  }
  .text__upeu {
    top: 150px;
    width: fit-content;
    font-size: 90px;
    margin: auto;
    left: 0;
    right: 0;
  }
  .my__modal {
    width: 80%;
  }
  .modal__text{
    font-size:30px
  }
  
}
@media only screen and (max-width: 750px) {
  .partial-circle{
    height:400px
  }
  .profile__image__contain__min {
    display: block;
  }
  .profile__image__contain {
    display: none;
  }
  
  .result__title {
    font-size: 25px;
  }
  .profile__title {
    text-align: center;
    font-size: 15px;
  }
  .profile__text {
    text-align: center;

    font-size: 15px;
  }
  .result__subtitle {
    font-size: 15px;
  }
  .result__profile {
    width: 60vw;
  }

  .choose__buttom {
    width: 200px;
    top: 0;
    margin: auto;
    display: block;
  }
  .choosed__images {
    margin-left: 0px;
  }
  .choosed__image {
    width: 60px;
    height: 54px;
  }

  .choose__buttom button {
    width: 150px;
  }

  .my__button {
    font-size: 15px;
  }
  .next__box {
    width: 100vw;
    display: inline-block;
  }

  .choose__subtitle {
    font-size: 20px;
    width: 80vw;
    margin: auto;
  }

  .choose__careers {
    width: 100vw;
  }

  .modal__text{
    font-size:30px
  }
}
@media only screen and (max-width: 500px) {
  .result__profile {
    width: 90vw;
  }
  .choosed__image {
    width: 45px;
    height: 39px;
  }
  .my__modal {
    width: 90%;
  }
  .text__test {
    font-size: 90px;
    bottom: 40vh;
    right: 0;
    left: 0;
    margin: auto;
    width: fit-content;
  }
  .text__upeu {
    width: fit-content;
    font-size: 70px;
    margin: auto;
    left: 0;
    right: 0;
  }
  .careers__title {
    font-size: 25px;
  }
  .mycol-3 {
    width: 90vw;
  }
  /* For mobile phones: */
  .mycol-4 {
    width: 90vw;
  }

  .box__notAbsolute {
    width: 32vw;
    height: 160px;
    border-radius: 20px;
  }

  .box__text {
    font-size: 13px;
    height: 50px;
  }

  .text__chat {
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
  }

  .svg__container {
    height: 70%;
  }
  .girl__container {
    width: 220px;
    right: 10px;
  }

  .boy__container {
    width: 180px;
    left: 10px;
  }
  .results__box {
    width: 90%;
    padding-top: 20px;
    top: 200px
  }

  .svg__container.results__woman {
    top: 100px;
    width: calc(80% - 20px);
    height: 100%;
  }

  .results__visualize{
    font-size:25px
  }

  .div__point{
    display: none
  }

  .results__title {
    font-size: 30px;
    margin: 5px
  }
  .results__hr{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .results__name{
    font-size: 40px;
  }
  .btn_container {
    bottom: 70px;
  }

  .text__test {
    top: -50px;
  }

  .text__upeu {
    top: 70px;
    font-size: 45px;
    left: 150px;
  }

  .form__title {
    font-size: 20px;
    margin: 15px;
  }
  .form__input {
    font-size: 12px;
  }

  .my__box {
    height: fit-content;
    margin: auto;
    font-size: 12px;
    padding: auto;
  }

  .upeu__logo {
    margin: auto;
    height: 10vh;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
  }

  .form__container {
    position: relative;
    height: 85vh;
    width: 100%;
  }

  .my__button {
    font-size: 15px;
  }

  .form__logo__contain {
    height: 20vh;
    position: relative;
  }

  .partial-circle {
    height: 50vh;
  }

  .partial-circle:after {
    content: "";
    position: absolute;
    height: 175vh;
    width: 250vw;
    left: -75vw;
    border-radius: 100%;
    bottom: 0;
    background: #003264;
  }

  .choose__careers {
    height: 60vh;
  }

  .box__title {
    font-size: 13px;
  }
  .test__text {
    left: 0;
    font-size: 20px;
  }

  .person__chat {
    display: none;
  }

  .results__text {
    font-size: 20px;
    width: 70vw;
    margin: auto;
  }

  .results__button {
    font-size: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .results__buttons {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .result__link {
    margin-top: 10px;
  }

  .my__link {
    font-size: 15px;
  }

  .career__button {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
  }

  .careers__list {
    width: 100%;
  }

  .career__name {
    font-size: 15px;
  }

  .career_check {
    width: 35px;
  }

  .careers__title {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .result__careers{
    top: 400px;
  }
}

@media only screen and (max-height: 520px){
  .form__logo__contain{
    display: none;
  }
}

.choose__careers::-webkit-scrollbar {
  width: 15px;
  z-index: 9999;
  position: absolute;
}

.choose__careers::-webkit-scrollbar-track {
  position: absolute;

}

.choose__careers::-webkit-scrollbar-thumb {
  background: #b7b7b7;
  border-radius: 10px;
  position: absolute;

}

.choose__careers::-webkit-scrollbar-thumb:hover {
  background: #b7b7b7;
}
