.gift__background {
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  top:0
}

.gift {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("../Assets/background.png");
  background-size: 100vw auto;
  opacity: 0.5;
  top:0
}

.load__container {
  position: absolute;
  display: flex;
  margin: auto;
  width: 250px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.load {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: auto;
}

.l1 {
  background: #003264;
}
.l2 {
  background: #003264;
}
.l3 {
  background: #003264;
}
.l4 {
  background: #003264;
}
