.start__background{
    background-color: #003264;
    height: 100vh;
    width: 100vw;
    position: absolute;
}
.start__background::before{
    content: "";
    background: url("../Assets/background.png");
    background-size: 100vw auto;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
    filter: brightness(10%);
}